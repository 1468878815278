exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-case-studies-index-jsx": () => import("./../../../src/pages/case-studies/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-insights-index-jsx": () => import("./../../../src/pages/insights/index.jsx" /* webpackChunkName: "component---src-pages-insights-index-jsx" */),
  "component---src-pages-our-services-index-jsx": () => import("./../../../src/pages/our-services/index.jsx" /* webpackChunkName: "component---src-pages-our-services-index-jsx" */),
  "component---src-pages-partnerships-index-jsx": () => import("./../../../src/pages/partnerships/index.jsx" /* webpackChunkName: "component---src-pages-partnerships-index-jsx" */),
  "component---src-pages-pricing-index-jsx": () => import("./../../../src/pages/pricing/index.jsx" /* webpackChunkName: "component---src-pages-pricing-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-terms-conditions-index-jsx": () => import("./../../../src/pages/terms-conditions/index.jsx" /* webpackChunkName: "component---src-pages-terms-conditions-index-jsx" */),
  "component---src-templates-insight-template-js": () => import("./../../../src/templates/insight-template.js" /* webpackChunkName: "component---src-templates-insight-template-js" */)
}

